import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
import checkAndRefreshToken from '../libs/token_refresh';

const PermissionPage: React.FC = () => {
  const [swalProps, setSwalProps] = useState<any>({ show: false });
  const [locationChecked, setLocationChecked] = useState<boolean>(false);
  const [cameraChecked, setCameraChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    checkAndRefreshToken(navigate);
    window.localStorage.removeItem('locationPermission');
    window.localStorage.removeItem('cameraPermission');
  }, [navigate]);

  const requestLocationPermission = useCallback(() => {
    if (navigator.geolocation && !locationChecked) {
      navigator.geolocation.getCurrentPosition(
        () => {
          window.localStorage.setItem('locationPermission', 'true');
          setLocationChecked(true);
        },
        (error) => {
          console.error('Location permission denied:', error);
          window.localStorage.setItem('locationPermission', 'false');
          setLocationChecked(false);
        }
      );
    }
  }, [locationChecked]);

  const requestCameraPermission = useCallback(async () => {
    if (!cameraChecked) {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        window.localStorage.setItem('cameraPermission', 'true');
        setCameraChecked(true);
      } catch (error) {
        console.error('Camera permission denied:', error);
        window.localStorage.setItem('cameraPermission', 'false');
        setCameraChecked(false);
      }
    }
  }, [cameraChecked]);

  useEffect(() => {
    const interval = setInterval(() => {
      requestLocationPermission();
      requestCameraPermission();
      setLoading(false);

      if (locationChecked && cameraChecked) {
        clearInterval(interval);
      }
    }, 5000); // Check every 3 seconds

    return () => clearInterval(interval);
  }, [locationChecked, cameraChecked, requestLocationPermission, requestCameraPermission]);

  return (
    <div className="container d-flex flex-column align-items-center" style={{ paddingTop: '50px' }}>
      <h2 className="mb-4">การขอเข้าถึงข้อมูล</h2>
      <p className="text-center mb-4">โปรดให้สิทธิ์การเข้าถึงตำแหน่งที่ตั้งและกล้องของคุณ</p>
      <div className="mb-3">
        <div className="form-check mb-2 d-flex align-items-center">
          {loading ? (
            <div className="spinner-border spinner-border-sm align-middle mr-2" role="status" />
          ) : (
            <img
              src={locationChecked ? '/299110_check_sign_icon.png' : '/299045_sign_error_icon.png'}
              alt="Location Permission Status"
              className="ms-3"
              width="24"
              height="24"
            />
          )}
          <label className="form-check-label ms-2" htmlFor="locationPermission">
            ตำแหน่งที่ตั้ง {locationChecked ? '(ได้รับอนุญาตแล้ว)' : '(ยังไม่ได้รับอนุญาต)'}
          </label>
        </div>
        <div className="form-check d-flex align-items-center">
          {loading ? (
            <div className="spinner-border spinner-border-sm align-middle mr-2" role="status" />
          ) : (
            <img
              src={cameraChecked ? '/299110_check_sign_icon.png' : '/299045_sign_error_icon.png'}
              alt="Camera Permission Status"
              className="ms-3"
              width="24"
              height="24"
            />
          )}
          <label className="form-check-label ms-2" htmlFor="cameraPermission">
            กล้อง {cameraChecked ? '(ได้รับอนุญาตแล้ว)' : '(ยังไม่ได้รับอนุญาต)'}
          </label>
        </div>
      </div>
      <div className="mb-3">
        <button
          className="btn btn-primary mt-4"
          onClick={() => navigate('/attendance')}
          disabled={!locationChecked || !cameraChecked}
        >
          ยืนยันการเข้าสู่หน้าลงเวลา
        </button>
      </div>
      <div className="mb-3">
        <div className="alert alert-info" role="alert">
          <p className="text-center mb-4">
            หากคุณไม่อนุญาตการเข้าถึงตำแหน่งที่ตั้งหรือกล้อง ระบบจะไม่สามารถทำงานได้อย่างถูกต้อง
            คุณสามารถเปลี่ยนแปลงการอนุญาตได้ในการตั้งค่าของเบราว์เซอร์:
          </p>
          <ul className="list-unstyled">
            <li className="mb-2">
              <a href="https://support.google.com/chrome/answer/2693767?hl=th" target="_blank" rel="noopener noreferrer">
                Chrome จัดการกล้องและไมโครโฟน
              </a>
            </li>
            <li className="mb-2">
              <a href="https://support.google.com/chrome/answer/142065?hl=th" target="_blank" rel="noopener noreferrer">
                Chrome จัดการตำแหน่งที่ตั้ง
              </a>
            </li>
            <li className="mb-2">
              <a href="https://support.apple.com/th-th/guide/iphone/iphb01fc3c85/ios" target="_blank" rel="noopener noreferrer">
                Safari จัดการกล้องและความเป็นส่วนตัว
              </a>
            </li>
            <li className="mb-2">
              <a href="https://support.apple.com/th-th/guide/personal-safety/ips9bf20ad2f/web" target="_blank" rel="noopener noreferrer">
                Safari จัดการการตั้งค่าบริการหาตำแหน่งที่ตั้ง
              </a>
            </li>
          </ul>
        </div>
      </div>
      <SweetAlert2 {...swalProps} onConfirm={() => setSwalProps({ show: false })} />
    </div>
  );
};

export default PermissionPage;
