import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import SweetAlert2 from 'react-sweetalert2';
import { Navbar, Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { groupBy, mapValues } from 'lodash';
import dayjs from 'dayjs';
import getApiRoot from '../libs/getAPIRoot';
import '../scss/attendancelogspage.scss';

interface User {
  name: string;
  username: string;
  id: number;
  created_at: string;
  updated_at: string;
}

interface AttendanceLog {
  image_path: string;
  lat: string | number | null;
  lon: string | number | null;
  time_record: string;
  checkin_at: string;
  updated_at: string;
  id: number;
  user: User;
}

const AttendanceLogsPage: React.FC = () => {
  const [attendanceLogs, setAttendanceLogs] = useState<Record<string, AttendanceLog[]>>({});
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
  const [activeLink, setActiveLink] = useState<'previous' | 'current'>('current');
  const [swalProps, setSwalProps] = useState<any>({ show: false });
  const monthNamesThai = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
  ];

  const navigate = useNavigate();

  const parseHexToFloat = (hexStr: string | null): number | null => {
    if (!hexStr) return null;
    try {
      const buffer = Buffer.from(hexStr.slice(2), 'hex');
      return parseFloat(buffer.toString('utf8'));
    } catch {
      return null;
    }
  };
  
  const formatDateThai = (dateStr: string): string => {
    const date = dayjs(dateStr);
    const day = date.format('D'); // วันที่
    const month = monthNamesThai[date.month()];
    const year = date.year()
  
    return `${day} ${month} ${year}`;
  };

  const getAllDatesInMonth = (year: number, month: number): string[] => {
    const dates: string[] = [];
    const date = new Date(year, month - 1, 1);
    while (date.getMonth() === month - 1) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD');
      dates.push(formattedDate);
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  const transformAttendanceLogs = useCallback(
    (logs: AttendanceLog[], year: number, month: number): Record<string, AttendanceLog[]> => {
      const toBangkokTime = (dateString: string): string => {
        return dayjs(dateString).add(7, 'hour').format();
      };

      // จัดกลุ่มข้อมูลตามวันที่
      const grouped = groupBy(logs, (log) =>
        dayjs(log.time_record).add(7, 'hour').format('YYYY-MM-DD')
      );

      // สร้างรายการวันที่ทั้งหมดในเดือน
      const allDates = getAllDatesInMonth(year, month);

      // รวมข้อมูลกับวันที่ทั้งหมด
      allDates.forEach((date) => {
        if (!grouped[date]) {
          grouped[date] = []; // ไม่มีการบันทึกในวันนั้น
        }
      });

      // แปลงค่าพิกัด
      return mapValues(grouped, (logs) =>
        logs.map((log) => ({
          ...log,
          lat: typeof log.lat === 'string' ? parseHexToFloat(log.lat) : log.lat,
          lon: typeof log.lon === 'string' ? parseHexToFloat(log.lon) : log.lon,
          time_record: toBangkokTime(log.time_record),
        }))
      );
    },
    []
  );

  useEffect(() => {
    const fetchTransformedAttendanceLogs = async () => {
      try {
        const token = window.localStorage.getItem('token');
        const uuid = uuidv4().replace(/-/g, '_');
        const response = await axios.get(`${getApiRoot()}attendance`, {
          headers: { 'Authorization': `Bearer ${token}`, 'x-client-uuid': uuid },
          params: { year: selectedYear, month: selectedMonth },
        });
        if (response.status === 200) {
          const transformedLogs = transformAttendanceLogs(response.data, selectedYear, selectedMonth);
          setAttendanceLogs(transformedLogs);
        } else {
          setSwalProps({
            show: true,
            title: 'ดึงข้อมูลไม่สำเร็จ',
            text: 'ไม่สามารถดึงข้อมูลตารางเวลามาได้',
            icon: 'error',
            confirmButtonText: 'Close',
          });
        }
      } catch (error) {
        setSwalProps({
          show: true,
          title: 'ดึงข้อมูลไม่สำเร็จ',
          text: 'เกิดข้อผิดพลาดขณะดึงตารางเวลา',
          icon: 'error',
          confirmButtonText: 'Close',
        });
      }
    };

    fetchTransformedAttendanceLogs();
  }, [selectedYear, selectedMonth, transformAttendanceLogs]);

  const handleCurrentMonth = () => {
    setSelectedYear(new Date().getFullYear());
    setSelectedMonth(new Date().getMonth() + 1);
    setActiveLink('current');
  };

  const handlePreviousMonth = () => {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();

    if (currentMonth === 0) {
      currentYear -= 1;
      currentMonth = 11;
    } else {
      currentMonth -= 1;
    }

    setSelectedYear(currentYear);
    setSelectedMonth(currentMonth + 1);
    setActiveLink('previous');
  };

  const previousMonthDate = new Date(selectedYear, selectedMonth - 2, 1); // ปรับเป็น selectedMonth - 1 เพื่อให้ตรงกับ selectedMonth
  const previousMonthName = monthNamesThai[previousMonthDate.getMonth()];
  const previousMonthYear = previousMonthDate.getFullYear();

  return (
    <React.Fragment>
      <SweetAlert2 {...swalProps} onConfirm={() => setSwalProps({ show: false })} />
      <div className="container mt-4">
        <h2 className="text-center">ประวัติการลงเวลา</h2>
        <Navbar bg="light" variant="light" className="justify-content-center mb-3">
          <div className="radio-button-group">
            <label className={`radio-label ${activeLink === 'previous' ? 'active' : ''}`}>
              <input
                type="radio"
                name="month"
                value="previous"
                checked={activeLink === 'previous'}
                onChange={handlePreviousMonth}
              />
              {previousMonthName} {previousMonthYear}
            </label>

            <label className={`radio-label ${activeLink === 'current' ? 'active' : ''}`}>
              <input
                type="radio"
                name="month"
                value="current"
                checked={activeLink === 'current'}
                onChange={handleCurrentMonth}
              />
              {monthNamesThai[new Date().getMonth()]} {new Date().getFullYear()}
            </label>
          </div>
        </Navbar>
        <h2 className="text-center">
          {activeLink === 'previous'
            ? `${previousMonthName} ${previousMonthYear}`
            : `${monthNamesThai[new Date().getMonth()]} ${new Date().getFullYear()}`}
        </h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>วันที่</th>
              <th>เวลาเข้า</th>
              <th>เวลาออก</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(attendanceLogs)
              .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime()) // เรียงจากวันที่น้อยไปมาก
              .map(([date, logs]) => {
                // หาก logs ว่างแสดงว่าไม่มีการบันทึก
                if (logs.length === 0) {
                  return (
                    <tr key={date}>
                      <td>{formatDateThai(date)}</td>
                      <td>N/A</td>
                      <td>N/A</td>
                    </tr>
                  );
                }

                // ถ้ามี logs ให้แสดงเวลาเข้าและออก
                const sortedLogs = logs.sort((a, b) => new Date(a.time_record).getTime() - new Date(b.time_record).getTime());
                const checkInTimes = sortedLogs.length > 1 ? sortedLogs.slice(0, -1) : sortedLogs;
                const checkOutTime = sortedLogs.length > 0 ? dayjs(sortedLogs[sortedLogs.length - 1].time_record).format('HH:mm:ss') : 'N/A';

                return (
                  <tr key={date}>
                    <td>{formatDateThai(date)}</td>
                    <td>
                      {checkInTimes.length > 0 ? (
                        <ul>
                          {checkInTimes.map((log, index) => (
                            <li key={index}>{dayjs(log.time_record).format('HH:mm:ss')}</li>
                          ))}
                        </ul>
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td>
                      {checkOutTime !== 'N/A' ? (
                        <ul>
                          <li>{checkOutTime}</li>
                        </ul>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Button variant="secondary" onClick={() => navigate('/attendance')}>
          ย้อนกลับ
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AttendanceLogsPage;
