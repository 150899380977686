import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FileUpload from '../libs/file_upload';
import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2';
import getApiRoot from '../libs/getAPIRoot';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import _ from 'lodash';

const ImportData: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<Record<string, any>[]>([]);
    const [uploadResponse, setUploadResponse] = useState<any[]>([]);
    const [showLogin, setShowLogin] = useState<boolean>(true);
    const [swalOptions, setSwalOptions] = useState<any>({
        show: showLogin,
        title: 'Admin Login',
        html:
            '<input id="swal-username" class="swal2-input" placeholder="Username">' +
            '<input id="swal-password" type="password" class="swal2-input" placeholder="Password">',
        confirmButtonText: 'Login',
        focusConfirm: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        preConfirm: async () => {
            const username = (document.getElementById('swal-username') as HTMLInputElement)?.value;
            const password = (document.getElementById('swal-password') as HTMLInputElement)?.value;

            let formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);
            const uuid = uuidv4().replace(/-/g, '_');
            
            if (!username || !password) {
                Swal.showValidationMessage('Please enter both username and password.');
                return false;
            }
            try {
                const response = await axios.post(`${getApiRoot()}admin/login`, formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                      'x-client-uuid': uuid,
                    },
                  });
                if (response.status === 200) {
                    setSwalOptions({})
                    return true;
                } else {
                    Swal.showValidationMessage('Invalid response from server.');
                    return false;
                }
            } catch (error: any) {
                const message = error.response?.data?.message || 'Invalid username or password. Please try again.';
                Swal.showValidationMessage(message);
                return false;
            }
        },
    });

    const handleDataUpload = (data: any[]) => {
        setTableData(data);
    };

    const handleUploadResponse = (response: any[]) => {
        setUploadResponse(response);
    };

    useEffect(() => {
        const acc = new URLSearchParams(location.search).get('acc');
        if (acc !== 'vpkdg0v,kd') {
            navigate('/');
        }
    }, [location, navigate]);

    return (
        <div className="card">
            <div className="row">
                <div className="card-body text-center">
                    <h2>Upload an Excel File</h2>
                    <FileUpload onDataUpload={handleDataUpload} onUploadResponse={handleUploadResponse} />
                    {uploadResponse.length > 0 && tableData.length > 0 && (
                        <div className="mt-4">
                            <h3>Excel Data</h3>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        {Object.keys(tableData[0]).map((key) => (
                                            <th key={key}>{key}</th>
                                        ))}
                                        <th key={'create'}>{'Create'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((row) => {
                                        const getDatarps = _.filter(uploadResponse, { username: row.Login });
                                        const math_user = getDatarps.length > 0;
                                        const status_change = getDatarps[0]?.status_change;

                                        return (
                                            <tr key={row.Login}>
                                                {Object.entries(row).map(([key, value]) => {
                                                    const class_color = key === 'Status' && status_change ? 'text-success' : '';
                                                    return (
                                                        <td key={key} className={class_color}>
                                                            {value}
                                                        </td>
                                                    );
                                                })}
                                                <td className={math_user && getDatarps[0]?.create ? 'text-success' : 'text-danger'}>
                                                    {math_user && getDatarps[0]?.create ? 'Yes' : 'No'}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
            <SweetAlert2
                {...swalOptions}
                didClose={() => {
                    setShowLogin(false);
                }}
                didConfirm={() => {
                    if (showLogin) {
                        setShowLogin(false);
                    }
                }}
            />
        </div>
    );
};

export default ImportData;
